<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Capped & Shown Report</h2>

        <div style="background-color: white; padding:2%;">
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="dateOne">State Date:</label>
                    <input type="date" v-model="filter.from" class="form-control date" id="dateOne" placeholder="From"/>
                </div>
                <div class="form-group col-md-2">
                    <label for="dateTwo">End Date:</label>
                    <input type="date" v-model="filter.to" class="form-control date" id="dateTwo" placeholder="To"/>
                </div>
                <div class="form-group col-md-2">
                    <label for="source">Traffic Source</label>
                    <select v-model="filter.source" @change="loadCities" id="source" class="form-control">
                        <option value="" selected>-- Any Source --</option>
                        <option v-for="state in clients" :key="state" :value="state">{{state}}</option>
                    </select>
                </div>
                <div class="form-group col-md-2">
                    <label for="linkId">Traffic Link ID(s)</label>
                    <input id="linkId" type="text" v-model="filter.linkId" class="form-control"
                           placeholder="Traffic Link Id"/>
                </div>
                <div class="form-group col-md-2">
                    <label for="state">State</label>
                    <select id="state" v-model="filter.state" @change="loadCities" class="form-control">
                        <option value="" selected>-- Any State --</option>
                        <option v-for="state in states" :key="state" :value="state">{{state}}</option>
                    </select>
                </div>
                <div class="form-group col-md-2">
                    <label for="cities">City</label>
                    <select id="cities" v-model="filter.city" class="form-control">
                        <option value="" selected>-- Any City --</option>
                        <option v-for="city in cities" :key="city._id" :value="city.city_name">{{city.city_name}}</option>
                    </select>

                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="Daily">Date Group BY:</label>
                    <select id="Daily" v-model="filter.daily" @change="loadCities" class="form-control">
                        <option  value="" selected>Summary</option>
                        <option  value="$date">Daily</option>
                    </select>
                </div>

                <!--            <div class="form-group col-md-2">-->
                <!--                <label for="traffic">Traffic Group By:</label>-->
                <!--                <select id="traffic" v-model="filter.traffic" @change="loadCities" class="form-control">-->
                <!--                    <option  value="" selected>Summary</option>-->
                <!--                    <option  value="TrafficSource">Traffic Source</option>-->
                <!--                    <option  value="TrafficLinkID">Traffic Link ID</option>-->
                <!--                </select>-->


                <!--            </div>-->
            </div>
            <div class="row">
                <div class="col-12 container">
                    <div class="text-center pb-4">

                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i
                                class="fa fa-search"
                                title="Search"
                            >
                            </i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="report">
                <div class="row mb-12" v-if="reportLoading">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body text-center">
                                <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                                <h4>Processing..............</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        <ejs-grid
                            ref="grid"
                            id="Grid"
                            :dataSource="report"
                            :allowPaging="true"
                            :allowExcelExport='true'
                            :pageSettings='pageSettings'
                            :filterSettings="filterSettings"
                            :allowSorting ='true'
                            :allowFiltering="true"
                            :allowGrouping="true"
                            :dataBound='dataBound'
                        >
                            <e-columns>

                                <e-column field='date' headerText='Date'></e-column>
                                <e-column field='TrafficSource' headerText='Source'></e-column>
                                <e-column field='TrafficLinkID' headerText='Link'></e-column>
                                <e-column field='TrafficWebsite' headerText='Website'></e-column>
                                <e-column field='State' headerText='State'></e-column>
                                <e-column field='City' headerText='City'></e-column>
                                <e-column field='Total' headerText='Total'></e-column>
                                <e-column field='Matched' headerText='Matched'></e-column>
                                <e-column field='M'  headerText='M %'></e-column>
                                <e-column field='Shown' headerText='Shown'></e-column>
                                <e-column field='S' format="C2" headerText='S %'></e-column>
                                <e-column field='Capped' headerText='Capped'></e-column>
                                <e-column field='C' headerText='C %'></e-column>

                            </e-columns>
                            <e-aggregates>
                                <e-aggregate>
                                    <e-columns>
                                        <e-column type="Sum" field="Total" format="N"  :footerTemplate='footerTotal'></e-column>
                                        <e-column type="Average" field='M' format="N" :footerTemplate="footerAvg"></e-column>
                                        <e-column type="Sum" field='Matched'  format="N" :footerTemplate="footerMatched"> </e-column>
                                        <e-column type="Sum" field='Shown' format="N" :footerTemplate="footerShown"  ></e-column>
                                        <e-column type="Average" field='S' format="N" :footerTemplate="footerSAvg"></e-column>
                                        <e-column type="Sum" field='Capped' format="N" :footerTemplate="footerCapped" ></e-column>
                                        <e-column type="Average" field='C' format="N" :footerTemplate=" footerCAvg" ></e-column>



                                        <e-column type="Sum" field="Total" format="N"  :groupFooterTemplate='footerTotal'></e-column>
                                        <e-column type="Average" field='M' format="N" :groupFooterTemplate="footerAvg"></e-column>
                                        <e-column type="Sum" field='Matched'  format="N" :groupFooterTemplate="footerMatched"> </e-column>
                                        <e-column type="Sum" field='Shown' format="N" :groupFooterTemplate="footerShown"  ></e-column>
                                        <e-column type="Average" field='S' format="N" :groupFooterTemplate="footerSAvg"></e-column>
                                        <e-column type="Sum" field='Capped' format="N" :groupFooterTemplate="footerCapped" ></e-column>
                                        <e-column type="Average" field='C' format="N" :groupFooterTemplate=" footerCAvg" ></e-column>
                                    </e-columns>
                                </e-aggregate>
                            </e-aggregates>
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter, Group} from "@syncfusion/ej2-vue-grids";
import Vue from "vue";
import swal from "sweetalert";

Vue.use(GridPlugin);

export default {
    name: 'CappedAndShownReport',
    props:['user'],
    components:{
        /*VSelect*/
    },
    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter, Group, ]
    },
    data: function () {
        return {
            items:[],
            campaign:null,
            filter:{
                to: '',
                from: '',
                campaign:'',
                state:'',
                city: '',
                linkId:'',
                daily:'',
                traffic:'',
                source:''

            },
            Total:"",
            date:false,
            state:false,
            city:false,

            campaigns:[],
            clients: [],
            states:[],
            source:[],
            cities:[],
            report:[],
            cappedShownDataset: {},
            pageSettings: {pageSize: 100},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,

            footerTotal: function () { //displays sum for total
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Total: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerMatched: function () {//displays sum for math
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Matched: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerShown: function () {//displays sum for shown
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Shown: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerCapped: function () { //displays sum for Capped
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Capped: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerAvg: function () { //displays average for M%
                return  { template : Vue.component('maxTemplate', {
                        template: `<span>M%: {{data.Average}}%</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerSAvg: function () { //displays average for S%
                return  { template : Vue.component('maxTemplate', {
                        template: `<span>S%: {{data.Average}}%</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerCAvg: function () { //displays average for C%
                return  { template : Vue.component('maxTemplate', {
                        template: `<span>C%: {{data.Average}}%</span>`,
                        data () {return { data: {}};}
                    })
                }
            },

        }
    },
    created: function(){
        this.load();
        window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    computed:{


    },
    methods:{

        dataBound: function() {

        },

        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        load: function () {
            //Load the campaign
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
            this.loadStates();
            this.loadClients()
            this.$root.preloader = false;
        },
        loadClients: function () {
            //Load the campaign
            let request = {'apikey': this.$root.apikey, filters: {}};
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());

            this.$root.preloader = true

            axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params: request}).then(function (resp) {
                this.clients = [];
                if (resp.data && resp.data.data) {
                    this.clients = this.clients.concat(resp.data.data);
                }
            }.bind(this));
        },
        onSearch(search, loading) {
            loading(true);
            this.campaignSearch(loading, search, this);
        },
        campaignSearch: function (loading, search, vm) {
            //Load the campaign
            var request = {'apikey': vm.$root.apikey, filters: {}};
            request.query = search;

            axios.get(`${vm.$root.serverUrl}/admin/campaigns/search`, {params: request}).then(function (resp) {
                vm.campaigns = (resp.data && resp.data.data) ? resp.data.data : null;
                loading(false);
            });
        },
        search: function(){

            if(new Date(this.filter.from) < this.getDateDifference()){
                swal({title:'Oops', text: `Out of range, please select dates within 2 months.`, icon: 'error'})
                return;
            }

            this.searching = true;
            this.showExportButton = false;
            this.reportLoading = true;
            this.report = [];
            this.cappedShownDataset = {};


            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    from: new Date(),
                    to: new Date()
                }
            };

            request.filters.from = this.filter.from;
            request.filters.to = this.filter.to;

            if (this.filter.campaign) {
                request.filters.campaign = this.filter.campaign;
                this.filter.state = "";
                this.filter.city = "";
            }

            if(this.filter.state) request.filters.state = this.filter.state;
            if(this.filter.city) request.filters.city = this.filter.city;
            if(this.filter.linkId) request.filters.TrafficLinkID = this.filter.linkId
            if(this.filter.traffic) request.filters.traffic = this.filter.traffic
            if(this.filter.daily) request.filters.daily = this.filter.daily
            if(this.filter.source) request.filters.source = this.filter.source


            axios.get(`${this.$root.serverUrl}/admin/reports/capped-and-shown`, {params: request}).then(function (resp) {
                //Store the stats
                if(resp.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                }else{
                    const result = resp.data.data;
                    if(result.length > 0){
                        const tasks = [];
                        for (let i in result) {
                            tasks.push(this.stage(result[i]));
                        }
                        Promise.all(tasks).then(function () {
                            for(const item in this.cappedShownDataset){
                             //done this way to display the percentage without the % mark so the average could be calculated
                                if(this.filter.daily == ""){
                                    this.report.push({//this is displayed for summary
                                        date:this.cappedShownDataset[item].date,
                                        C: parseFloat(this.cappedShownDataset[item].C).toFixed(2),
                                        Capped:this.cappedShownDataset[item].Capped,
                                        City:this.cappedShownDataset[item].City,
                                        M:parseFloat(this.cappedShownDataset[item].M).toFixed(2),
                                        Matched:this.cappedShownDataset[item].Matched,
                                        S:parseFloat(this.cappedShownDataset[item].S).toFixed(2),
                                        Shown:this.cappedShownDataset[item].Shown,
                                        State:this.cappedShownDataset[item].State,
                                        Total:this.cappedShownDataset[item].Total,
                                        TrafficCategory:this.cappedShownDataset[item].TrafficCategory,
                                        TrafficLinkID:this.cappedShownDataset[item].TrafficLinkID,
                                        TrafficLocale:this.cappedShownDataset[item].TrafficLocale,
                                        TrafficSource:this.cappedShownDataset[item].TrafficSource,
                                        TrafficWebsite: this.cappedShownDataset[item].TrafficWebsite})
                                }else{
                                    this.report.push({//this returns all the data
                                        date:this.cappedShownDataset[item].date,
                                        C: this.cappedShownDataset[item].C.replace("%", ""),
                                        Capped:this.cappedShownDataset[item].Capped,
                                        City:this.cappedShownDataset[item].City,
                                        M:this.cappedShownDataset[item].M.replace("%", ""),
                                        Matched:this.cappedShownDataset[item].Matched,
                                        S:this.cappedShownDataset[item].S.replace("%", ""),
                                        Shown:this.cappedShownDataset[item].Shown,
                                        State:this.cappedShownDataset[item].State,
                                        Total:this.cappedShownDataset[item].Total,
                                        TrafficCategory:this.cappedShownDataset[item].TrafficCategory,
                                        TrafficLinkID:this.cappedShownDataset[item].TrafficLinkID,
                                        TrafficLocale:this.cappedShownDataset[item].TrafficLocale,
                                        TrafficSource:this.cappedShownDataset[item].TrafficSource,
                                        TrafficWebsite: this.cappedShownDataset[item].TrafficWebsite})
                                }

                                // this.report.push(this.cappedShownDataset[item]); //this is were all the data is being stored to
                            }
                            this.showExportButton = true;
                            this.reportLoading = false;
                        }.bind(this));
                    }else{
                        this.reportLoading = false;
                    }
                }
                this.searching = false;
            }.bind(this)).catch((err) => {
                this.reportLoading = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            });
        },
        stage: function (dataset) {
            return new Promise(function (resolve) {
                if (!this.cappedShownDataset.hasOwnProperty(dataset._id)) {
                    this.cappedShownDataset[dataset._id] = dataset;
                }
                /*this.cappedShownDataset[dataset._id].details.push({
                    log_id: dataset._id,
                    log_domain: dataset.domain,
                    log_node: dataset.node,
                    log_date: this.getDate(dataset.date),
                    log_time: this.getTime(dataset.date),
                    log_type: dataset.type,
                    log_message: dataset.message,
                    log_tracker: dataset.tracker,
                    log_proxy_id: dataset.proxy_id,
                    log_proxy_ip: dataset.proxy_ip,
                    log_proxy_port: dataset.proxy_port,
                    log_proxy_provider:dataset.proxy_provider,
                    log_proxy_protocol: dataset.proxy_protocol,
                    log_proxy_country: dataset.proxy_country,
                    log_proxy_state: dataset.proxy_state,
                    log_proxy_city: dataset.proxy_city,
                    log_proxy_weight: dataset.proxy_weight,
                    log_proxy_latitude: dataset.proxy_latitude,
                    log_proxy_longitude: dataset.proxy_longitude,
                    log_proxy_timezone: dataset.proxy_timezone,
                    log_log_campaign_id: dataset.campaign_id,
                    log_campaign_name: dataset.campaign_name,
                    log_campaign_type: dataset.campaign_type,
                    log_client_id: dataset.client_id,
                    log_client_name: dataset.client_name
                });*/
                return resolve();
            }.bind(this));
        },
        loadStates: function(){
            this.states = [];
            var request = {
                'apikey': this.$root.apikey,
                filters:{
                    country_iso_code: 'US'
                }
            };
            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadCities: function(){
            this.cities = [];
            this.filter.city = "";
            var request = {'apikey': this.$root.apikey,filters:{}};

            request.filters.country_iso_code = 'US';
            if(this.filter.state) request.filters.subdivision_1_iso_code = this.filter.state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                this.cities = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to}_capped_and_shown_report`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.report
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getDateFormatted: function(d){
            var
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        getDateDifference: function(){
            const toDate = new Date(this.filter.to);
            let fromDate = new Date(this.filter.to);
            //fromDate.setDate(toDate.getDate()+1);  //day was off by a day
            fromDate.setMonth(toDate.getMonth()-2); //set two months in the past
            return fromDate;
        },
        fixDates: function(){
            this.filter.from = this.getDateFormatted(this.getDateDifference());
        }
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}

.d-center {
    display: flex;
    align-items: center;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
    color: #fff;
}
</style>
